export const navData = [
    {
      id: 1,
      key: "home",
      title: "Home",
      ariaLabel: "Go Home"
    },
    {
      id: 2,
      key: "about",
      title: "About",
      ariaLabel: "Go To About Section"
    },
    {
      id: 3,
      key: "work",
      title: "Work", 
      ariaLabel: "Go To Work Section"
    },
 
  ]