export const ArrowLink = (props) => {
  return (
    <svg 
      aria-hidden="true"
      data-prefix="fab"
      data-icon="github"
      className="prefix__svg-inline--fa prefix__fa-github prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 7 8"
      {...props}
      >
      <path
        fill={props.fill}
        d="M3 7.477L3.5 8l.5-.522 3-3.135-.5-.522-2.682 2.802V0H0v.739h3.11v5.81L.5 3.82l-.5.522 3 3.134z"
      />
    </svg>
  )
}

export const Github = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="github"
      className="prefix__svg-inline--fa prefix__fa-github prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 512"
      {...props}
    >
      <path
        fill={props.fill}
        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
      />
    </svg>
  )
}

export const Linkedin = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="linkedin"
      className="jam jam-linkedin-circle"
      preserveAspectRatio="xMinYMin"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2 -2 24 24"
      {...props}
    >
      <path fill={props.fill} d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z"></path>
    </svg>
  )
}
export const Email = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="email"
      className="jam jam-email-circle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
      {...props}
    >
      <path fill={props.fill} d="M61.44 0A61.46 61.46 0 1118 18 61.21 61.21 0 0161.44 0zM32.22 79.39L52.1 59.46 32.22 43.25v36.14zm22.07-18.15l-20.5 20.55h55.12L69.33 61.24l-6.46 5.51a1.42 1.42 0 01-1.8 0l-6.78-5.53zm17.18-1.82l19.19 20.13V43.07L71.47 59.42zM34 41.09l27.9 22.76 26.75-22.76zm65.4-17.64a53.72 53.72 0 1015.74 38 53.56 53.56 0 00-15.74-38z"></path>
    </svg>
  )
}

export const Arrow = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fab"
      data-icon="arrow"
      className="prefix__svg-inline--fa prefix__fa-arrow prefix__fa-w-16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill={props.fill}
        d="M21.5 2A1.5 1.5 0 0020 .5H6.5a1.5 1.5 0 100 3h12v12a1.5 1.5 0 003 0V2zM3.06 21.06l18-18L18.94.94l-18 18 2.12 2.12z"
      ></path>
    </svg>
  )
}
  
